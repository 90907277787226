import { makeStyles } from 'tss-react/mui';
var facilityDetailHeader = makeStyles()(function (theme) {
    var _a, _b, _c;
    return ({
        container: {
            backgroundColor: theme.palette.common.white,
            // height: '106px',
            borderRadius: '12px',
            boxShadow: "0 2px 8px 0 ".concat(theme.palette.customColor.boxShadow),
            alignItems: 'center',
            padding: '1em 1.5em',
            margin: '30px 0',
        },
        /* InnerContainer: {
          height: 'inherit',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-around',
        }, */
        InnerContainer: (_a = {
                height: 'inherit',
                alignItems: 'baseline',
                display: 'grid',
                gridTemplateColumns: '1fr 270px',
                gap: '1em'
            },
            _a[theme.breakpoints.down('md')] = {
                gridTemplateColumns: '1fr',
            },
            _a),
        gList: (_b = {
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(390px, 1fr))',
                gap: '1em'
            },
            _b[theme.breakpoints.down('lg')] = {
                gridTemplateColumns: 'repeat(auto-fit, minmax(330px, 1fr))',
            },
            _b[theme.breakpoints.down('sm')] = {
                gridTemplateColumns: 'repeat(auto-fit, minmax(260px, 1fr))',
            },
            _b),
        headList: (_c = {
                display: 'flex',
                alignItems: 'center',
                '& > span': {
                    minWidth: '140px',
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }
            },
            _c[theme.breakpoints.down('sm')] = {
                flexWrap: 'wrap',
                '& > span': {
                    minWidth: '100%',
                    display: 'block',
                },
            },
            _c),
        button: {
            color: theme.palette.common.white,
            padding: '8px 22px',
            fontWeight: '600',
            fontSize: '16px',
            borderRadius: 0,
        },
    });
});
export default facilityDetailHeader;
