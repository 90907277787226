var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import React from 'react';
import ResidentTable from './component/ResidentTable';
import { Box, Typography, Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { API_LOAD_STATE, CareInsightStatus, PAGINATION_TYPE, PaginationFetchTypes, Roles, } from 'globals/enums';
import ZoneSelect from 'common/ZoneSelect/ZoneSelect.component';
import withPaginationTable from 'hoc/withPaginationIsolated/withPaginationTable';
import { SENIOR_HOME_SEARCH, SET_HOME_ZONE, getSeniorList, getSeniorListFail, getSeniorListSuccess, getSeniorSearchListSuccess, resetHomeSearch, resetHomeZone, resetSeniorList, searchSeniorByName, showError, updateSeniorListPageNumber, } from 'store/commonReducer/common.action';
import { AllResidentsStyle } from './AllResidents.style';
import WellnessCoachAutoComplete from 'pages/WCPages/Home/components/WellnessCoachAutoComplete';
import { PAGINATION_LIMIT, facilitySlugs } from 'globals/global.constants';
import { useParams } from 'react-router-dom';
import { RESET_CARE_AGENTS } from 'pages/WCPages/Admin/Accounts/AgentAccount/CareAgentAccount.actions';
import { RESET_SENIOR_LIST_ADMINS } from 'pages/WCPages/Admin/Accounts/CWMemberRecords/CWMemberRecords.actions';
import FacilityDetailHeader from 'common/FacilityDetailHeader/FacilityDetailHeader';
import { useMutation, useQuery } from 'utilities/react-query';
import { getAllFacilityCareInsightEventsService, putAcknowledgeCareInsights, } from 'services/careInsightService/insightSummary.service';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { removeFacilitySummary } from 'store/eventsReducer/Events.action';
import FacilityTypeSelect from 'common/FacilityTypeSelect/FacilityTypeSelect.component';
import { SET_FACILITY_TYPE_FILTER } from 'pages/WCPages/Admin/CorporateAndFacilities/FacilityManagement/FacilityManagement.action';
import RoleBaseRender from 'common/RoleBaseRender/RoleBaseRender';
import { capitalizeFirstLetter } from 'globals/global.functions';
import InfoIcon from '@mui/icons-material/Info';
var AllResidents = function () {
    var classes = AllResidentsStyle().classes;
    var selectedWc = useAppSelector(function (state) { return state.common.seniorList.selectedHomeWc; });
    var selectedZone = useAppSelector(function (state) { return state.common.seniorList.selectedHomeZone; });
    var facilityTypes = useAppSelector(function (state) {
        return state.corporateAndFacilities.facilityManagementData.selectedFacility
            .facilityType;
    });
    var selectedFacilityType = useAppSelector(function (state) {
        return state.corporateAndFacilities.facilityManagementData.facilityTypeFilter;
    });
    var agentFacilityType = useAppSelector(function (state) { var _a; return ((_a = state.auth.facilityDetails) === null || _a === void 0 ? void 0 : _a.facility_type) || ''; });
    var facilityTypeOption = React.useMemo(function () {
        var facilityName = agentFacilityType;
        if (agentFacilityType === 'memory_care') {
            facilityName = 'home_care';
        }
        return facilityName
            .split('_')
            .map(function (x) { return capitalizeFirstLetter(x, true); })
            .join(' ');
    }, [agentFacilityType]);
    var dispatch = useAppDispatch();
    var params = useParams();
    var userRole = useAppSelector(function (state) { return state.auth.userRole; });
    var pusherData = useAppSelector(function (state) { return state.events.facilitySummary || []; });
    // query to get facility summary highlight data
    var _a = useQuery({
        queryKey: ['facilitySummaryData'],
        queryFn: function () {
            return getAllFacilityCareInsightEventsService({
                facility_id: params[facilitySlugs.facilityHomePage] || '',
                status: CareInsightStatus.Approved,
            });
        },
        cacheTime: 0,
        enabled: userRole.includes(Roles.FACILITY_AGENT),
    }).data, data = _a === void 0 ? [] : _a;
    // mutation to acknowledge facility summary notification for a senior
    var acknowledgeSummaryMutation = useMutation({
        mutationFn: function (params) {
            dispatch(showApplicationLoader());
            return putAcknowledgeCareInsights(params);
        },
        onSuccess: function () {
            dispatch(hideApplicationLoader());
        },
        onError: function (error) {
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    });
    // careInsight data aggregated from pusher & api
    var highlightData = React.useMemo(function () {
        return __spreadArray(__spreadArray([], pusherData, true), data, true);
    }, [pusherData, data]);
    // HOC props
    var withPaginationProps = React.useMemo(function () {
        var commonProps = {
            getData: function (searchQuery, lastEvaluatedKey) {
                return getSeniorList(searchQuery, lastEvaluatedKey, [], null, false, '', true, true, selectedWc === null || selectedWc === void 0 ? void 0 : selectedWc.userId, selectedZone, params[facilitySlugs.facilityHomePage], true);
            },
            onSuccess: getSeniorListSuccess,
            onError: getSeniorListFail,
            onSearchSuccess: getSeniorSearchListSuccess,
            lastEvaluatedKeyPath: 'common.seniorList.lastEvaluatedKey',
            tableDataPath: 'common.seniorList.data',
            loadingPath: 'common.seniorList.loading',
        };
        return {
            fetchType: PaginationFetchTypes.LAST_EVALUATED_KEY,
            // constructing dependency using all possible filter values
            dependency: "".concat(selectedZone || selectedZone === '').concat((selectedWc === null || selectedWc === void 0 ? void 0 : selectedWc.userId) || '').concat(params[facilitySlugs.facilityHomePage] || '').concat(selectedFacilityType !== null && selectedFacilityType !== void 0 ? selectedFacilityType : ''),
            paginationProps: __assign(__assign({}, commonProps), { onPageChange: updateSeniorListPageNumber, rowsPerPage: PAGINATION_LIMIT.homeSenior, className: classes.pagination, paginationType: PAGINATION_TYPE.PRIMARY, tableDataPath: 'common.seniorList.data', isScrollOnTop: true, pagePath: 'common.seniorList.currentPage', searchLastEvaluatedKeyPath: 'common.seniorList.searchLastEvaluatedKey' }),
            searchBarProps: __assign(__assign({}, commonProps), { onSearchChange: function (value) {
                    dispatch({ type: SENIOR_HOME_SEARCH, payload: value }); // will trigger with debounce
                }, className: classes.searchContainer, searchMethod: searchSeniorByName, disableFrontendSearch: true, noRightMargin: true, renderLeftSideComponent: function (isDataLoading) {
                    return (_jsxs(Box, { className: classes.filterSec, children: [_jsx(RoleBaseRender, { forRole: Roles.Admin, children: _jsx(FacilityTypeSelect, { disabled: isDataLoading === API_LOAD_STATE.PROGRESS, selectedType: selectedFacilityType, facilityTypeMenu: facilityTypes, hasAllOption: true, onChange: function (e) {
                                        var value = e.target.value;
                                        dispatch({
                                            type: SET_FACILITY_TYPE_FILTER,
                                            payload: value,
                                        });
                                    } }) }), _jsx(RoleBaseRender, { forRole: Roles.FACILITY_AGENT, children: _jsx(FacilityTypeSelect, { disabled: true, selectedType: facilityTypeOption, facilityTypeMenu: [facilityTypeOption] }) }), _jsx(WellnessCoachAutoComplete, { disabled: isDataLoading === API_LOAD_STATE.PROGRESS }), _jsx(ZoneSelect, { disabled: isDataLoading === API_LOAD_STATE.PROGRESS, zoneType: selectedZone, onChange: function (e) {
                                    var value = e.target.value;
                                    dispatch({
                                        type: SET_HOME_ZONE,
                                        payload: value,
                                    });
                                }, hasAllOption: true })] }));
                } }),
        };
    }, [
        selectedWc,
        selectedZone,
        params,
        facilityTypes,
        selectedFacilityType,
    ]);
    /**
     * @description Wrap component withPagination HOC to get the pagination service
     */
    var seniorWCMappingWithPagination = withPaginationTable(ResidentTable, {
        isHighlighted: function (record) {
            // function that returns true or false for a record in the table to highlight it
            if (userRole.includes(Roles.FACILITY_AGENT)) {
                var highlights = (highlightData === null || highlightData === void 0 ? void 0 : highlightData.filter(function (summary) { return summary.seniorId === record.senior_id; })) || [];
                return !!highlights.length;
            }
            return false;
        },
        onRowClick: function (rowData) {
            // acknowledge (Unread -> Read) when facility agent clicks on a senior
            if (userRole.includes(Roles.FACILITY_AGENT)) {
                var highlights = (highlightData === null || highlightData === void 0 ? void 0 : highlightData.filter(function (summary) { return summary.seniorId === rowData.senior_id; })) || [];
                var careInsightIds = highlights.map(function (summary) { return summary.careInsightId; });
                dispatch(removeFacilitySummary(careInsightIds));
                acknowledgeSummaryMutation.mutate({
                    senior_id: rowData.senior_id,
                    account_id: rowData.account_id,
                    care_insight_id: careInsightIds,
                });
            }
        },
    }, withPaginationProps);
    React.useEffect(function () {
        return function () {
            dispatch(resetSeniorList());
            dispatch({ type: RESET_CARE_AGENTS });
            dispatch(resetHomeSearch());
            dispatch(resetHomeZone());
        };
    }, [dispatch]);
    React.useEffect(function () {
        return function () {
            dispatch(resetSeniorList());
            dispatch({ type: RESET_SENIOR_LIST_ADMINS });
            dispatch(resetHomeSearch());
            dispatch(resetHomeZone());
        };
    }, [dispatch]);
    var tooltipText = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Your All-In-One Dashboard" }), _jsx(Typography, { component: 'div', children: "Unlock a wealth of resident information, progress tracking, and direct communication with wellness coaches through this comprehensive dashboard. Stay informed, motivated, and connected every step of the way." })] }));
    return (_jsxs(Box, { children: [_jsx(FacilityDetailHeader, { isFacilityButton: true }), _jsxs(Box, { className: classes.paginationTableContainer, children: [_jsxs(Box, { className: classes.title, style: { display: "inline-flex" }, children: [_jsx(Typography, { variant: 'h2', children: "Residents" }), _jsx(Tooltip, { title: tooltipText, children: _jsx(Box, { children: _jsx(InfoIcon, { style: { marginLeft: "8px", height: "24px", width: "24px" } }) }) })] }), seniorWCMappingWithPagination()] })] }));
};
export default AllResidents;
