import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import moment from 'moment-timezone';
import { NavLink, useLocation } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import { DATE_FORMAT } from 'globals/global.constants';
import { FACILITY_TYPES, OnboardingTab } from 'globals/enums';
import { theme } from 'config/theme.config';
import { getSeniorResidentBaseUrl } from 'globals/global.functions';
import { getDateDifference } from 'globals/date.functions';
var SeniorShortDetail = function (_a) {
    var _b, _c, _d;
    var data = _a.data, isResident = _a.isResident;
    var location = useLocation();
    var getCurrentAge = function (value) {
        var ageDifMs = Date.now() - new Date(value).getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };
    var getSeniorOnboardingURL = getSeniorResidentBaseUrl();
    var memberTypeColors = {
        'At Home': '#FF5733', // Red
        'Beta Tester': '#670E10', // Green
        'Internal Tester': '#3357FF', // Blue
        Study: 'brown',
        Community: '#8E44AD', // Purple
        'Home Care Member': '#AA336A', // Orange
        NA: 'red', // Color for 'NA'
    };
    var memberType = (_b = data === null || data === void 0 ? void 0 : data.member_type) !== null && _b !== void 0 ? _b : 'NA';
    var memberTypeColor = memberTypeColors[memberType] || memberTypeColors['NA']; // Get color based on member type
    var list = [
        {
            label: 'Member Since',
            route: isResident
                ? ''
                : getSeniorOnboardingURL.seniorUrlWithTab('onboarding-info', OnboardingTab.PROFILE_INFO),
            value: data.created_date
                ? moment(data.created_date).format(DATE_FORMAT)
                : '',
            isVisible: true,
        },
        {
            label: 'Member Type',
            route: isResident
                ? ''
                : getSeniorOnboardingURL.seniorUrlWithTab('onboarding-info', OnboardingTab.PROFILE_INFO),
            value: (_c = data === null || data === void 0 ? void 0 : data.member_type) !== null && _c !== void 0 ? _c : 'NA',
            color: memberTypeColor, // Add color here
            isVisible: true,
        },
        {
            label: 'Member ID',
            route: isResident
                ? ''
                : getSeniorOnboardingURL.seniorUrlWithTab('onboarding-info', OnboardingTab.PROFILE_INFO),
            value: (_d = data === null || data === void 0 ? void 0 : data.member_id) !== null && _d !== void 0 ? _d : 'NA',
            isVisible: true,
        },
        {
            label: 'DOB/Age',
            route: isResident
                ? ''
                : getSeniorOnboardingURL.seniorUrlWithTab('onboarding-info', OnboardingTab.PROFILE_INFO),
            value: data.dob
                ? "".concat(moment.utc(data.dob).format('MM-DD-YYYY'), "/").concat(getCurrentAge(data.dob), " yrs")
                : '',
            isVisible: true,
        },
        {
            label: 'Community',
            route: '',
            value: data.facility,
            isVisible: data.isResident || false,
        },
        {
            label: 'Length of Stay',
            route: '',
            value: getDateDifference(moment(), data === null || data === void 0 ? void 0 : data.created_date),
            isVisible: (isResident &&
                data.isResident &&
                data.facility_type === FACILITY_TYPES.INDEPENDENT_LIVING) ||
                false,
        },
    ];
    return (_jsx(Grid, { container: true, children: list.map(function (item) {
            return item.isVisible && (_jsxs(Grid, { container: true, style: { display: 'grid', margin: '0.3em 0', gridTemplateColumns: 'repeat(auto-fit, minmax(170px, 1fr))' }, children: [_jsx(Grid, { item: true, children: _jsx(Typography, { style: { fontWeight: 'bold', whiteSpace: 'nowrap' }, variant: 'body1', children: item.label }, item.label) }), _jsx(Grid, { item: true, children: _jsxs(Typography, { style: {
                                wordBreak: 'break-all',
                                color: item.label === 'Member Type'
                                    ? item.color
                                    : theme.palette.common.black,
                            }, variant: 'body1', children: [":\u00A0", item.value ? (_jsx(NavLink, { to: item.route || location.pathname, style: {
                                        color: item.label === 'Member Type'
                                            ? item.color
                                            : theme.palette.common.black
                                    }, children: item.value })) : ('-')] }, item.label) })] }, item.label));
        }) }));
};
export default React.memo(SeniorShortDetail);
