import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { NavLinks } from 'common/NavLinks';
import UserName from 'common/UserName';
import MoreOptions from './MoreOptions';
import ClearWellnessLogo from 'assets/ClearWellness_White.png';
import { headerStyle } from './Header.style';
import { Button, Typography, useTheme } from '@mui/material';
import { getClientTimezone, getTimezoneFullAbbr } from 'globals/date.functions';
import { PRINT_HIDE_CLASS } from 'common/Print/Print.types';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxHooks';
import ErrorIcon from 'common/Icons/ErrorIcon';
import FormatAlignRightOutlinedIcon from '@mui/icons-material/FormatAlignRightOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
var Header = function (_a) {
    var showMenu = _a.showMenu;
    var classes = headerStyle().classes;
    var theme = useTheme();
    var defaultPage = useAppSelector(function (state) { var _a; return (_a = state.auth.roleConfig) === null || _a === void 0 ? void 0 : _a.defaultPage; });
    var isPasswordResetWarning = useAppSelector(function (state) { var _a; return (_a = state.auth.passwordCreatedData) === null || _a === void 0 ? void 0 : _a.isPasswordResetWarning; });
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(false), isToggled = _c[0], setIsToggled = _c[1];
    var handleToggle = function () {
        setIsToggled(function (prevState) { return !prevState; });
    };
    return (_jsxs(Box, { component: 'header', boxShadow: 3, className: "".concat(classes.container, " ").concat(PRINT_HIDE_CLASS), children: [_jsx(Box, { children: _jsx(NavLink, { to: defaultPage, style: { display: 'block', width: '190px', height: 'auto' }, children: _jsx("img", { src: ClearWellnessLogo, alt: 'ClearWellness-logo', width: '100%', height: 'auto' }) }) }), showMenu && (_jsxs(Box, { className: isToggled ? 'media-nav show-nav' : 'media-nav', children: [_jsx(Typography, { style: { color: theme.palette.common.white }, children: getTimezoneFullAbbr(getClientTimezone()) }), _jsx(NavLinks, {}), _jsxs(Typography, { className: 'user-list', variant: 'body1', children: [isPasswordResetWarning && (_jsx(Box, { display: 'flex', width: '30px', children: !open && _jsx(ErrorIcon, {}) })), _jsx(UserName, { firstName: true }), _jsx(MoreOptions, { open: open, setOpen: setOpen })] })] })), _jsxs(Button, { className: "media-btn", onClick: handleToggle, children: [_jsx(FormatAlignRightOutlinedIcon, { className: isToggled ? 'nav-icon' : 'nav-icon show-icon' }), _jsx(ClearOutlinedIcon, { className: isToggled ? 'nav-icon show-icon' : 'nav-icon' })] })] }));
};
export default Header;
