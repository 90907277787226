import { makeStyles } from 'tss-react/mui';
export var ResidentTableStyle = makeStyles()(function (theme) { return ({
    avatarStyle: {
        margin: '0 10px 0 0',
        width: '45px',
        height: '45px',
        border: "solid 1px #ccc",
        fontSize: '15px',
        background: '#ebebeb',
        color: '#000',
    },
    tableCellStyle: {
        '&.MuiTableCell-root': {
            verticalAlign: 'middle',
            padding: '6px 0',
        },
    },
}); });
