import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, CardActionArea, CardContent, CardMedia, Typography, Tooltip } from '@mui/material';
import facilityDashboard from 'assets/icons/facilityDashboard.png';
import facilityResidents from 'assets/icons/facilityResidents.png';
import AppCard from 'common/AppCard';
import FacilityDetailHeader from 'common/FacilityDetailHeader/FacilityDetailHeader';
import { facilityHomeScreenTheme } from './FacilityHomeScreen.style';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
var HomeScreenCard = function (_a) {
    var img = _a.img, heading = _a.heading, detailText = _a.detailText, buttonText = _a.buttonText, onClick = _a.onClick, imageStyle = _a.imageStyle, tooltipText = _a.tooltipText;
    var classes = facilityHomeScreenTheme().classes;
    return (_jsx(AppCard, { className: classes.cardWidth, children: _jsxs(CardActionArea, { className: classes.cardAction, onClick: onClick, children: [_jsxs(Box, { className: classes.imageWrapper, children: [_jsx(Tooltip, { title: tooltipText, placement: 'right', children: _jsx(InfoIcon, { sx: {
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    zIndex: 1,
                                    color: 'rgba(60, 60, 60)',
                                    backgroundColor: 'white',
                                    borderRadius: '50%',
                                    height: '24px',
                                    width: '24px',
                                } }) }), _jsx(CardMedia, { component: 'img', className: classes.cardMedia, style: imageStyle, image: img, alt: 'facility dashboard' })] }), _jsxs(CardContent, { className: classes.cardContent, children: [_jsx(Typography, { variant: 'boldHeading', component: 'div', children: heading }), _jsx(Typography, { variant: 'body1', mt: 2, height: '49px', children: detailText }), _jsx(Button, { color: 'primary', variant: 'contained', className: classes.cardButton, children: buttonText })] })] }) }));
};
var FacilityHomeScreen = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var handleClick = function () {
        navigate("".concat(location.pathname, "/residents"));
    };
    var handleDashboardClick = function () {
        navigate("".concat(location.pathname, "/facilityDashboard"));
    };
    var dashboardTooltip = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h6', component: 'div', sx: { fontWeight: 'bold' }, children: "Explore Our Community Dashboard" }), _jsx(Typography, { component: 'div', children: "Our dashboard provides a comprehensive overview of resident trends encompassing activity levels, holistic assessments, emotional well-being, and duration of stay within the ClearWellness service. Dive in to make data-driven decisions and enhance resident care." })] }));
    var residentsTooltip = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h6', component: 'div', sx: { fontWeight: 'bold' }, children: "Resident Overview" }), _jsx(Typography, { component: 'div', children: "Tap the Resident icon to access a comprehensive list of individuals enrolled in the ClearWellness service within your community. Easily navigate through resident profiles to stay updated and connected." })] }));
    return (_jsxs(Box, { className: 'facility-card', children: [_jsx(FacilityDetailHeader, {}), _jsxs(Box, { style: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
                    gap: '1em'
                }, children: [_jsx(HomeScreenCard, { img: facilityDashboard, tooltipText: dashboardTooltip, heading: 'Community Dashboard', detailText: 'At a glance trends and metrics for your facility', buttonText: 'View Community Dashboard', onClick: handleDashboardClick }), _jsx(HomeScreenCard, { img: facilityResidents, heading: 'Residents', detailText: 'Individual resident wellness data. Real time and trending data.\n        Messaging from their ClearWellness Wellness Coach', buttonText: 'View Residents', onClick: handleClick, imageStyle: {
                            width: '217px',
                            height: '111px',
                        }, tooltipText: residentsTooltip })] })] }));
};
export default FacilityHomeScreen;
