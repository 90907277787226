var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { Box, CircularProgress } from '@mui/material';
import MapComponent from 'common/GoogleMap';
import { resetSeniorLocation } from 'store/commonReducer/common.action';
import { getCurrentSenior } from 'globals/global.functions';
import LocationDateSelector from './LocationDateSelector';
import { LocationInfo } from './LocationInfo.component';
import { seniorLocationStyle } from './SeniorLocation.style';
var SeniorLocation = function () {
    var dispatch = useAppDispatch();
    var classes = seniorLocationStyle().classes;
    var coordinates = useAppSelector(function (state) { return state.common.seniorDetail.minimalInfo.address; }).coordinates;
    var seniorLocation = useAppSelector(function (state) { return state.common.seniorLocation.locationData; });
    var isRenderLocation = useAppSelector(function (state) { return state.events.isRenderLocation; });
    var seniorTimezone = getCurrentSenior().timezone;
    React.useEffect(function () {
        return function () {
            dispatch(resetSeniorLocation());
        };
    }, [dispatch]);
    return (_jsxs(Box, { className: classes.seniorLocationContainer, "data-testid": 'senior-location', children: [isRenderLocation ? (_jsxs(_Fragment, { children: [_jsx(Box, { className: classes.locaDateSel, children: _jsx(LocationDateSelector, { coordinates: coordinates }) }), _jsx(Box, { mb: 2, children: _jsx(LocationInfo, __assign({ seniorTimezone: seniorTimezone }, seniorLocation)) })] })) : (_jsx(Box, { className: classes.locationDetailsLoader, children: _jsx(CircularProgress, { size: 30, thickness: 3.6, style: { marginLeft: 10 } }) })), _jsx(Box, { className: classes.mapContainer, children: _jsx(MapComponent, { atHome: seniorLocation.atHome, isLoading: coordinates.latitude == '' &&
                        !seniorLocation.currentCoordinates.latitude, currentCoordinates: seniorLocation.currentCoordinates, homeCoordinates: {
                        latitude: coordinates === null || coordinates === void 0 ? void 0 : coordinates.latitude,
                        longitude: coordinates === null || coordinates === void 0 ? void 0 : coordinates.longitude,
                    }, historyData: seniorLocation.historyData, mapOptions: { clickableIcons: false }, className: classes.mapStyle, timezone: seniorTimezone, showTooltipOnMap: true }) })] }));
};
export default SeniorLocation;
