import { makeStyles } from 'tss-react/mui';
export var subHeaderStyle = makeStyles()(function (theme) { return ({
    container: {
        display: 'grid',
        gap: '0.5em',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        borderBottom: "4px solid ".concat(theme.palette.customColor.primaryGreen),
        '& > div': {
            width: '100%',
            margin: '0px',
        }
    },
    button: {
        backgroundColor: theme.palette.customColor.white,
        color: theme.palette.customColor.primaryGreen,
        borderRadius: '0px',
        position: 'relative',
        bottom: 4,
        width: '100%',
        border: "1px solid ".concat(theme.palette.customColor.primaryGreen),
    },
    filled: {
        backgroundColor: theme.palette.customColor.primaryGreen,
        color: theme.palette.customColor.white,
        pointerEvents: 'none',
    },
    notification: {
        backgroundColor: theme.palette.customColor.notification,
        height: 33,
        width: 33,
        borderRadius: '50%',
        position: 'absolute',
        top: -20,
        right: -8,
    },
    incomplete: {
        borderRadius: '50%',
        backgroundColor: theme.palette.customColor.white,
        width: '21px',
        height: '21px',
        overflow: 'hidden',
        position: 'absolute',
        right: '7.5px',
        top: '7.5px',
    },
}); });
