import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { getScoreAndTarget, } from './component/ZoneChart/ZoneChart.types';
import { MONTHS } from 'globals/global.constants';
import ZoneChart from './component/ZoneChart/ZoneChart.component';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip, Typography } from '@mui/material';
var WellbeingGraph = function (_a) {
    var _b, _c, _d, _e;
    var graphState = _a.graphState, queryData = _a.queryData;
    var wellbeingGraphData = queryData.wellbeingGraphData, isWellbeingGraphLoading = queryData.isWellbeingGraphLoading, isWellbeingGraphRefetching = queryData.isWellbeingGraphRefetching;
    /**
     * @description function to convert Wellbeing to Graph Data
     * @function getGraphDataFromWellbeingGraphData
     * @param {IWellbeingGraphData[]} zoneData
     * @returns {IZoneGraphRecord[]} graphData
     */
    var getGraphDataFromWellbeingGraphData = React.useCallback(function (zoneData) {
        if (zoneData === void 0) { zoneData = []; }
        var graphData = zoneData === null || zoneData === void 0 ? void 0 : zoneData.map(function (_a) {
            var timestamp = _a.timestamp, score = _a.score;
            var d = new Date(timestamp);
            return {
                x: MONTHS[d.getUTCMonth()],
                y: score || 0,
            };
        });
        return graphData || [];
    }, []);
    var wellbeingData = React.useMemo(function () {
        var _a, _b, _c, _d;
        return {
            overall: getGraphDataFromWellbeingGraphData((_a = wellbeingGraphData === null || wellbeingGraphData === void 0 ? void 0 : wellbeingGraphData.overall) === null || _a === void 0 ? void 0 : _a.data),
            blue: getGraphDataFromWellbeingGraphData((_b = wellbeingGraphData === null || wellbeingGraphData === void 0 ? void 0 : wellbeingGraphData.blue) === null || _b === void 0 ? void 0 : _b.data),
            green: getGraphDataFromWellbeingGraphData((_c = wellbeingGraphData === null || wellbeingGraphData === void 0 ? void 0 : wellbeingGraphData.green) === null || _c === void 0 ? void 0 : _c.data),
            gold: getGraphDataFromWellbeingGraphData((_d = wellbeingGraphData === null || wellbeingGraphData === void 0 ? void 0 : wellbeingGraphData.gold) === null || _d === void 0 ? void 0 : _d.data),
        };
    }, [
        getGraphDataFromWellbeingGraphData,
        (_b = wellbeingGraphData === null || wellbeingGraphData === void 0 ? void 0 : wellbeingGraphData.blue) === null || _b === void 0 ? void 0 : _b.data,
        (_c = wellbeingGraphData === null || wellbeingGraphData === void 0 ? void 0 : wellbeingGraphData.gold) === null || _c === void 0 ? void 0 : _c.data,
        (_d = wellbeingGraphData === null || wellbeingGraphData === void 0 ? void 0 : wellbeingGraphData.green) === null || _d === void 0 ? void 0 : _d.data,
        (_e = wellbeingGraphData === null || wellbeingGraphData === void 0 ? void 0 : wellbeingGraphData.overall) === null || _e === void 0 ? void 0 : _e.data,
    ]);
    var wellbeingScore = React.useMemo(function () {
        return getScoreAndTarget(graphState.zones, wellbeingGraphData);
    }, [wellbeingGraphData, graphState.zones]).emotionalScore;
    var tooltipText = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Emotional Well-Being Score Insight" }), _jsx(Typography, { component: 'div', children: "The average emotional well-being score reflects collective resident responses in the ClearWellness program during each call. This metric provides an overview of the community's emotional health and serves as a vital indicator of the overall well-being support offered." })] }));
    return (_jsx(ZoneChart, { state: graphState, data: wellbeingData, title: _jsx(Tooltip, { title: tooltipText, placement: "right", children: _jsxs(Typography, { variant: "h6", children: ["Average Emotional Assessment:", _jsx(InfoIcon, { fontSize: "medium", style: { display: "inline-flex", marginLeft: "152px", position: "relative", top: "5px" } })] }) }), current: true, currentLabel: 'Current Month Average:', target: 6.5, showTarget: true, currentValue: "".concat(wellbeingScore), isLoading: isWellbeingGraphLoading || isWellbeingGraphRefetching, yAxisProps: {
            tickValues: [0, 1, 2, 3, 4, 5, 6, 7],
        } }));
};
export default WellbeingGraph;
