import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import SearchBar from 'common/SearchBar/SearchBar';
import get from 'lodash.get';
import React from 'react';
import { getPaginationDataIsolated, getPaginationOffsetData, } from 'store/commonReducer/common.action';
import { PaginationSearchDispatchContext, UPDATE_FILTER_DATA, UPDATE_FILTER_LOADER, UPDATE_SEARCH_QUERY, } from './PaginationSearchContext';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { API_LOAD_STATE, PaginationFetchTypes } from 'globals/enums';
var SearchContainer = React.memo(function (_a) {
    var getDataMemberId = _a.getDataMemberId, renderRightSideComponent = _a.renderRightSideComponent, searchMethod = _a.searchMethod, searchMemberMethod = _a.searchMemberMethod, lastEvaluatedKeyPath = _a.lastEvaluatedKeyPath, getData = _a.getData, onError = _a.onError, onSuccess = _a.onSuccess, onSearchSuccess = _a.onSearchSuccess, isNewDesign = _a.isNewDesign, placeholder = _a.placeholder, position = _a.position, _b = _a.tableDataPath, tableDataPath = _b === void 0 ? '' : _b, _c = _a.disableFrontendSearch, disableFrontendSearch = _c === void 0 ? false : _c, renderLeftSideComponent = _a.renderLeftSideComponent, onSearchChange = _a.onSearchChange, _d = _a.noRightMargin, noRightMargin = _d === void 0 ? false : _d, className = _a.className, fetchType = _a.fetchType, rowsPerPage = _a.rowsPerPage, limit = _a.limit, loadingPath = _a.loadingPath, _e = _a.showSearchBarMemberID, showSearchBarMemberID = _e === void 0 ? false : _e, _f = _a.searchQueryName, searchQueryName = _f === void 0 ? '' : _f, _g = _a.searchQueryMemberId, searchQueryMemberId = _g === void 0 ? '' : _g;
    var contextDispatch = React.useContext(PaginationSearchDispatchContext);
    var isDataLoading = useAppSelector(function (state) { return get(state, loadingPath || ''); });
    var lastEvaluatedKey = useAppSelector(function (state) {
        return get(state, lastEvaluatedKeyPath || '', '');
    }) || '';
    var tableData = useAppSelector(function (state) { return get(state, tableDataPath, []); }) || [];
    var dispatch = useAppDispatch();
    var handleSearchChange = function (value, lastEvaluatedKey, tableData, checkFilterMethod) {
        if (onSearchChange) {
            onSearchChange(value, checkFilterMethod);
        }
        if (fetchType === PaginationFetchTypes.OFFSET) {
            dispatch(getPaginationOffsetData(checkFilterMethod == "member_id" ? getDataMemberId : getData, rowsPerPage || 10, 1, 0, limit, onSuccess, onError, [], value, onSearchSuccess));
        }
        else if (lastEvaluatedKey || value === null || disableFrontendSearch) {
            contextDispatch({ type: UPDATE_SEARCH_QUERY, searchQuery: value });
            dispatch(getPaginationDataIsolated(checkFilterMethod == "member_id" ? getDataMemberId : getData, 1, '', 1, onSuccess, onError, [], '', value, onSearchSuccess));
        }
        else {
            // frontend logic
            contextDispatch({
                type: UPDATE_FILTER_LOADER,
                isFilterLoading: true,
            });
            if (value) {
                var filteredData = null;
                if (checkFilterMethod != "name") {
                    filteredData = searchMemberMethod(tableData || [], value);
                }
                else {
                    filteredData = searchMethod(filteredData || [], value);
                }
                contextDispatch({
                    type: UPDATE_FILTER_DATA,
                    filteredData: filteredData,
                });
            }
            else {
                contextDispatch({
                    type: UPDATE_FILTER_DATA,
                    filteredData: null,
                });
                contextDispatch({ type: UPDATE_SEARCH_QUERY, searchQuery: value });
            }
            // settimout to display loader for 300ms
            setTimeout(function () {
                contextDispatch({
                    type: UPDATE_FILTER_LOADER,
                    isFilterLoading: false,
                });
            }, 300);
        }
    };
    return (_jsxs(Grid, { gap: 1, "data-testid": 'SearchContainer', container: true, justifyContent: position || 'flex-end', className: "home-filter", children: [renderLeftSideComponent && renderLeftSideComponent(isDataLoading), _jsx(SearchBar, { handleSearchChange: function (value, lastEvaluatedKey, tableData) {
                    return handleSearchChange(value, lastEvaluatedKey, tableData, "name");
                }, lastEvaluatedKey: lastEvaluatedKey, tableData: tableData, isNewDesign: isNewDesign, placeholder: placeholder, noRightMargin: noRightMargin, disabled: isDataLoading === API_LOAD_STATE.PROGRESS, loading: loadingPath, value: searchQueryName }), showSearchBarMemberID && (_jsx(SearchBar, { handleSearchChange: function (value, lastEvaluatedKey, tableData) {
                    return handleSearchChange(value, lastEvaluatedKey, tableData, "member_id");
                }, lastEvaluatedKey: lastEvaluatedKey, tableData: tableData, type: "number", maxLength: 6, value: searchQueryMemberId, isNewDesign: isNewDesign, placeholder: 'Search by Member ID', noRightMargin: noRightMargin, disabled: isDataLoading === API_LOAD_STATE.PROGRESS, loading: loadingPath })), renderRightSideComponent && renderRightSideComponent()] }));
});
export default SearchContainer;
