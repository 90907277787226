var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useAppSelector } from 'hooks/reduxHooks';
import { getCurrentSenior, getQueryParamTimezone, } from 'globals/global.functions';
import { AssessmentStatuses, EventViewState, Roles } from 'globals/enums';
import RoleBaseRender from 'common/RoleBaseRender';
import { subHeaderStyle } from './SubHeader.style';
import ErrorIcon from 'assets/icons/ErrorIcon.svg';
var SubHeader = React.memo(function (props) {
    var navigate = useNavigate();
    var location = useLocation();
    var classes = subHeaderStyle().classes;
    var _a = useState('dashboard'), currentRoute = _a[0], setCurrentRoute = _a[1];
    var alert = useAppSelector(function (state) { return state.events.alert; });
    var summary = useAppSelector(function (state) { return state.events.summary; });
    var userRole = useAppSelector(function (state) { return state.auth.userRole; });
    var roleConfig = useAppSelector(function (state) { return state.auth.roleConfig; });
    var careInsight = __assign(__assign({}, alert), summary);
    var seniorId = useAppSelector(function (state) { return state.common.seniorDetail.minimalInfo.user_id; });
    var assessmentStatuses = useAppSelector(function (state) { return state.assessments.assessmentStatuses; });
    var isAssessmentIncomplete = React.useMemo(function () {
        var res = Object.keys(assessmentStatuses).some(function (assessmentKey) {
            var _a;
            var status = (_a = assessmentStatuses[assessmentKey]) === null || _a === void 0 ? void 0 : _a.status;
            // for wellness-survey only consider Incomplete Status
            if (assessmentKey === 'wellness-survey') {
                return status === AssessmentStatuses.INCOMPLETE;
            }
            // for holistic and care give strain assessments only consider complete status
            if (assessmentKey === 'holistic-assessment' ||
                assessmentKey === 'caregiver-strain-assessment') {
                status = AssessmentStatuses.COMPLETE;
            }
            // for all others consider Incomplete Status & Due Status
            return (status === AssessmentStatuses.DUE ||
                status === AssessmentStatuses.INCOMPLETE);
        });
        return res;
    }, [assessmentStatuses]);
    var isActionNotification = Object.values(careInsight).some(function (data) {
        return data.viewState === EventViewState.ActionNotification &&
            data.seniorId === seniorId;
    });
    var lists = [
        {
            name: 'dashboard',
            label: 'Dashboard',
            roles: [Roles.Admin, Roles.CareAgent],
        },
        {
            name: 'onboarding-info',
            label: 'Onboarding Info',
            roles: [Roles.Admin, Roles.CareAgent, Roles.WarehouseEmployee, Roles.BDM, Roles.FACILITY_AGENT],
        },
        {
            name: 'wellness-plan',
            label: 'Wellness Plan',
            roles: [Roles.Admin, Roles.CareAgent],
        },
        {
            name: 'assessments',
            defaultPath: '/wellness-survey',
            label: 'Assessments',
            roles: [Roles.Admin, Roles.CareAgent],
            showIncomplete: isAssessmentIncomplete,
        },
        {
            name: 'scheduler',
            label: 'Scheduler',
            roles: [Roles.Admin, Roles.CareAgent],
        },
        {
            name: 'call-schedule',
            label: 'Call Schedule',
            roles: [Roles.Admin, Roles.CareAgent],
        },
        {
            name: 'call-logs',
            label: 'Call Log',
            roles: [Roles.Admin, Roles.CareAgent],
        },
        {
            name: 'care-insights',
            label: 'Care Insights',
            defaultPath: '/threshold-setting',
            showNotification: isActionNotification,
            roles: [Roles.Admin, Roles.CareAgent],
        },
        {
            name: 'senior-location',
            label: 'Location',
            roles: [Roles.Admin, Roles.CareAgent],
        },
    ];
    /**
     * @function changeRoute
     * @description method to change the route
     * @param {*} path
     * @param {*} defaultPath
     * @returns void
     */
    var changeRoute = function (path, defaultPath) {
        if (defaultPath === void 0) { defaultPath = ''; }
        var seniorInfo = __assign({}, getCurrentSenior());
        navigate("/senior/".concat(seniorInfo.seniorID, "/").concat(seniorInfo.accountID, "/").concat(getQueryParamTimezone(seniorInfo.timezone), "/").concat(path).concat(defaultPath), { replace: true });
    };
    useEffect(function () {
        lists.every(function (_a) {
            var name = _a.name;
            if (location.pathname.includes(name)) {
                setCurrentRoute(name);
                return false;
            }
            else {
                return true;
            }
        });
        if (userRole.includes(Roles.WarehouseEmployee)
        //||userRole.includes(Roles.BDM)
        ) {
            changeRoute(roleConfig.defaultHomeRoute);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (Array.isArray(userRole)) {
        if (userRole.includes(Roles.BDM) || userRole.includes(Roles.FACILITY_AGENT)) {
            return null;
        }
    }
    if (props.noHeader) {
        return null;
    }
    return (_jsx(Box, { mb: '1em', className: classes.container, children: lists.map(function (listData, index) {
            return (_jsx(RoleBaseRender, { forRole: listData.roles, children: _jsx(Lists, { name: listData.name, defaultPath: listData.defaultPath, label: listData.label, listItem: lists.length, currentRoute: currentRoute, changeRoute: changeRoute, disabled: listData.disabled ? listData.disabled : false, marginRight: index !== lists.length - 1, showNotification: listData.showNotification, showIncomplete: listData.showIncomplete }, listData.name) }, listData.name));
        }) }));
});
var Lists = function (_a) {
    var _b;
    var name = _a.name, defaultPath = _a.defaultPath, label = _a.label, listItem = _a.listItem, currentRoute = _a.currentRoute, changeRoute = _a.changeRoute, disabled = _a.disabled, marginRight = _a.marginRight, showNotification = _a.showNotification, showIncomplete = _a.showIncomplete;
    var classes = subHeaderStyle().classes;
    return (_jsxs(Box, { mr: marginRight ? 1 : 0, width: "".concat(100 / listItem, "%"), style: { position: 'relative' }, children: [_jsxs(Button, { className: clsx([classes.button], (_b = {},
                    _b[classes.filled] = currentRoute == name,
                    _b)), disabled: disabled, onClick: function () { return changeRoute(name, defaultPath); }, children: [label, showIncomplete && (_jsx(Box, { className: classes.incomplete, children: _jsx("img", { src: ErrorIcon, width: '21', height: '21' }) }))] }), showNotification && _jsx(Box, { className: classes.notification })] }));
};
export { SubHeader };
