import { makeStyles } from 'tss-react/mui';
export var seniorLocationStyle = makeStyles()(function (theme) {
    var _a;
    return ({
        seniorLocationContainer: {
            backgroundColor: '#fff',
            padding: '2em',
            borderRadius: '16px',
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.16)',
        },
        mapContainer: {
            height: '65vh',
            width: '100%',
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        mapStyle: {
            borderRadius: 16,
        },
        locationDetailsLoader: {
            height: '132px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
            marginTop: '116px',
            borderRadius: 16,
            padding: 30,
            border: '1px solid #00a9cf',
        },
        locaDateSel: (_a = {
                display: 'flex',
                justifyContent: 'flex-end',
                flexWrap: 'wrap',
                marginBottom: '24px'
            },
            _a[theme.breakpoints.down('md')] = {
                justifyContent: 'center',
            },
            _a)
    });
});
