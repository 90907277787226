import { makeStyles } from 'tss-react/mui';
export var facilityBreadcrumbs = makeStyles()(function (theme) {
    var _a;
    return ({
        breadcrumb: (_a = {
                backgroundColor: theme.palette.background.green,
                padding: '1.5em',
                borderRadius: '16px',
                'ol li h6': {
                    fontSize: 'inherit',
                }
            },
            _a[theme.breakpoints.down('md')] = {
                padding: '1em',
            },
            _a),
        disableLink: {
            pointerEvents: 'none',
            color: 'grey',
        },
    });
});
