import { jsx as _jsx } from "react/jsx-runtime";
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import RoleBaseRender from '../RoleBaseRender';
import { Roles } from 'globals/enums';
import { appRoutesEndpoints } from 'routes/appRoutesEndpoints';
import { useAppSelector } from 'hooks/reduxHooks';
var useStyles = makeStyles()(function (theme) { return ({
    links: {
        color: theme.palette.common.white,
    },
    activeLink: {
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
}); });
var NavLinks = function () {
    var classes = useStyles().classes;
    var location = useLocation();
    var defaultPage = useAppSelector(function (state) { return state.auth.roleConfig.defaultPage; });
    var navLinks = [
        {
            label: 'Admin',
            // eslint-disable-next-line max-len
            path: defaultPage,
            roles: [Roles.Admin, Roles.BDM],
        },
        {
            label: 'Home',
            path: appRoutesEndpoints.homePage,
            roles: [Roles.CareAgent, Roles.WarehouseEmployee, Roles.FACILITY_AGENT, Roles.BDM],
        },
        {
            label: 'Add User',
            path: '/add-user',
            roles: [Roles.CareAgent, Roles.WarehouseEmployee, Roles.BDM, Roles.FACILITY_AGENT],
        },
        {
            label: 'Call Schedule',
            path: '/call-schedule',
            roles: [Roles.CareAgent],
        },
    ];
    return (_jsx(Box, { className: "nav-list", display: 'flex', marginRight: 2, children: navLinks.map(function (data) {
            var isActive = location.pathname === data.path;
            return (_jsx(RoleBaseRender, { forRole: data.roles, children: _jsx(Box, { padding: 2, children: _jsx(Typography, { variant: 'body1', children: _jsx(Link, { className: "".concat(classes.links, " ").concat(isActive ? classes.activeLink : ''), to: data.path, children: data.label }) }) }, data.label) }, data.label));
        }) }));
};
export { NavLinks };
