import { makeStyles } from 'tss-react/mui';
export var appStyle = makeStyles()(function (theme) {
    var _a;
    return ({
        layout: (_a = {
                // marginLeft: '5%',
                // marginRight: '5%',
                width: '90%',
                margin: 'auto',
                marginTop: "90px"
            },
            _a[theme.breakpoints.down('xl')] = {
            // marginLeft: '2%',
            // marginRight: '2%',
            },
            _a),
    });
});
