import { makeStyles } from 'tss-react/mui';
var facilityDashboard = makeStyles()(function (theme) {
    var _a;
    return ({
        container: {
            backgroundColor: theme.palette.common.white,
            borderRadius: '12px',
            padding: '1em',
            boxShadow: "0 2px 8px 0 ".concat(theme.palette.customColor.boxShadow),
            alignItems: 'center',
        },
        zoneGraphContainer: (_a = {
                display: 'grid',
                gap: '1em',
                gridTemplateColumns: 'repeat(auto-fit, minmax(420px, 1fr))'
            },
            _a[theme.breakpoints.down('sm')] = {
                gridTemplateColumns: 'repeat(auto-fit, minmax(260px, 1fr))',
            },
            _a),
    });
});
export default facilityDashboard;
