import { makeStyles } from 'tss-react/mui';
export var blueTableStyles = makeStyles()(function (theme) {
    var _a, _b, _c, _d;
    return ({
        table: {
            '& th, td': {
                fontSize: '13px',
                padding: '0',
            },
            '& th': {
                borderBottom: "1px solid ".concat((_a = theme.palette.customColor) === null || _a === void 0 ? void 0 : _a.darkSeparator),
                padding: 0,
            },
            '& td': {
                verticalAlign: 'top',
                fontWeight: 'normal',
            },
        },
        row: {
            cursor: 'pointer',
            '&.active': {
                '& > td': {
                    backgroundColor: (_b = theme.palette.customColor) === null || _b === void 0 ? void 0 : _b.activeCellBackground,
                },
            },
        },
        singleSelectColorPrimary: {
            '&.Mui-checked': {
                color: (_c = theme.palette.customColor) === null || _c === void 0 ? void 0 : _c.primaryLight,
            },
        },
        highlight: {
            '& > td': {
                backgroundColor: (_d = theme.palette.customColor) === null || _d === void 0 ? void 0 : _d.highlightYellow,
                '&:first-of-type': {
                    borderLeft: "4px solid ".concat(theme.palette.primary.main),
                },
            },
        },
    });
});
export var greenTableStyles = makeStyles()(function (theme) {
    var _a;
    return ({
        table: {
            '& > thead > tr > th': {
                backgroundColor: theme.palette.background.green,
                fontWeight: 'bold',
            },
            '& th, td': {
                fontSize: '13px',
                padding: '0',
            },
            '& th': {
                borderBottom: "1px solid ".concat(theme.palette.border.main),
                padding: '16px 0',
            },
            '& td': {
                verticalAlign: 'top',
                fontWeight: 'normal',
            },
            '& tbody tr:nth-child(even)': {
                backgroundColor: theme.palette.background.default,
            },
            '& tbody tr:nth-child(odd)': {
                backgroundColor: theme.palette.background.paper,
            },
        },
        row: {
            cursor: 'pointer',
            '&.active': {
                '& > td': {
                    backgroundColor: theme.palette.background.green,
                },
            },
        },
        singleSelectColorPrimary: {
            '&.Mui-checked': {
                color: theme.palette.primary.light,
            },
        },
        highlight: {
            '& > td': {
                backgroundColor: (_a = theme.palette.customColor) === null || _a === void 0 ? void 0 : _a.highlightYellow,
                '&:first-of-type': {
                    borderLeft: "4px solid ".concat(theme.palette.primary.main),
                },
            },
        },
    });
});
