var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import moment from 'moment';
import 'moment-timezone';
import { useEffect, useState } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { OpenPickerIcon } from 'common/InputFields/component/Input.component';
import { getTimezoneFullAbbr } from 'globals/date.functions';
import { GraphView } from 'globals/enums';
import { DATE_FORMAT } from 'globals/global.constants';
import { getCurrentSenior } from 'globals/global.functions';
import { setSelectedDate } from '../WellnessDashboard.actions';
import { wellnessDashboardStyle } from '../WellnessDashboard.style';
import { RE_RENDER_GRAPH, SET_CURRENT_STATE, SET_START_END_TIME, } from '../WellnessDashboard.types';
import MonthPicker from './MonthPicker.component';
import WeekPicker from './WeekPicker.component';
export var OpenCalenderIcon = function () {
    return _jsx(CalendarTodayIcon, { fontSize: 'small' });
};
var WellnessWrapper = function (_a) {
    var _b, _c, _d;
    var children = _a.children, wellnessParam = _a.wellnessParam;
    var theme = useTheme();
    var classes = wellnessDashboardStyle().classes;
    var _e = useAppSelector(function (state) { return state.wellnessDashboard; }), currentState = _e.currentState, selectedDate = _e.selectedDate;
    var dispatch = useAppDispatch();
    var _f = useState(GraphView.DAY), buttonState = _f[0], setButtonState = _f[1];
    var selectedDay = useAppSelector(function (state) { return state.wellnessDashboard.selectedDate.day; });
    var timezone = getCurrentSenior().timezone;
    useEffect(function () {
        setButtonState(currentState);
        switch (currentState) {
            case GraphView.DAY:
                setCurrentDay(selectedDate.day);
                return;
            case GraphView.WEEK:
                setCurrentWeek(selectedDate.week);
                return;
            case GraphView.MONTH:
                setCurrentMonth(selectedDate.month);
                return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState, selectedDate]);
    var setCurrentDay = function (currentDate) {
        if (currentDate === void 0) { currentDate = moment().format('L'); }
        var startT = '';
        var endT = '';
        startT =
            moment(currentDate, DATE_FORMAT).startOf(GraphView.DAY).valueOf() *
                1000000;
        endT =
            moment(currentDate, DATE_FORMAT).endOf(GraphView.DAY).valueOf() * 1000000;
        dispatch({
            type: SET_START_END_TIME,
            startTime: startT,
            endTime: endT,
        });
        dispatch({ type: RE_RENDER_GRAPH, value: true });
    };
    var setCurrentWeek = function (dt) {
        var startOfWeek = moment(dt).clone().startOf(GraphView.WEEK).format('x');
        var endOfWeek = moment(dt).clone().endOf(GraphView.WEEK).format('x');
        dispatch({
            type: SET_START_END_TIME,
            startTime: parseInt(startOfWeek) * 1000000,
            endTime: parseInt(endOfWeek) * 1000000,
        });
        dispatch({ type: RE_RENDER_GRAPH, value: true });
    };
    var setCurrentMonth = function (mnth) {
        var startOfMonth = moment(mnth)
            .clone()
            .startOf(GraphView.MONTH)
            .format('x');
        var endOfMonth = moment(mnth).clone().endOf(GraphView.MONTH).format('x');
        dispatch({
            type: SET_START_END_TIME,
            startTime: parseInt(startOfMonth) * 1000000,
            endTime: parseInt(endOfMonth) * 1000000,
        });
        dispatch({ type: RE_RENDER_GRAPH, value: true });
    };
    var setWellnessState = function (state) {
        if (currentState !== state) {
            dispatch({ type: RE_RENDER_GRAPH, value: false });
            dispatch({ type: SET_CURRENT_STATE, currentState: state });
        }
    };
    var navigatePrevDay = function () {
        var prevDate = moment(selectedDay).subtract(1, 'd');
        dispatch(setSelectedDate(prevDate, GraphView.DAY));
        setCurrentDay(prevDate.format(DATE_FORMAT));
    };
    var navigateNextDay = function () {
        var nextDate = moment(selectedDay).add(1, 'd');
        if (moment(selectedDay).format('L') == moment().format('L')) {
            return false;
        }
        dispatch(setSelectedDate(nextDate, GraphView.DAY));
        setCurrentDay(nextDate.format(DATE_FORMAT));
    };
    return (_jsxs(Box, { width: '100%', children: [_jsxs(Box, { className: classes.wellnessContainer, "data-testid": 'wellness-wrapper', children: [_jsxs(Box, { children: [_jsx(Button, { className: clsx([classes.wellnessButton], (_b = {},
                                    _b[classes.selectedButton] = buttonState == GraphView.DAY,
                                    _b)), variant: 'contained', color: 'primary', "data-testid": 'day-button', onClick: setWellnessState.bind(null, GraphView.DAY), children: "Day" }), _jsx(Button, { className: clsx([classes.wellnessButton], (_c = {},
                                    _c[classes.selectedButton] = buttonState == GraphView.WEEK,
                                    _c)), variant: 'contained', color: 'primary', "data-testid": 'week-button', onClick: setWellnessState.bind(null, GraphView.WEEK), children: "Week" }), _jsx(Button, { className: clsx([classes.wellnessButton], (_d = {},
                                    _d[classes.selectedButton] = buttonState == GraphView.MONTH,
                                    _d)), variant: 'contained', color: 'primary', "data-testid": 'month-button', onClick: setWellnessState.bind(null, GraphView.MONTH), children: "Month" })] }), _jsxs(Box, { display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '1em', children: [wellnessParam === 'sleep' && (_jsxs(Box, { display: 'flex', alignItems: 'center', children: [_jsx(InfoOutlinedIcon, { style: { marginRight: 5 } }), _jsxs(Typography, { variant: 'body1', style: { marginRight: 15 }, children: ["Sleep graph data is displayed based on the \u00A0", getTimezoneFullAbbr(timezone)] })] })), currentState === GraphView.DAY && (_jsxs(Box, { display: 'flex', alignItems: 'center', "data-testid": 'day-picker', children: [_jsx(NavigateBeforeIcon, { style: {
                                            cursor: 'pointer',
                                            color: theme.palette.customColor.primaryGreen,
                                        }, onClick: navigatePrevDay }), _jsxs(LocalizationProvider, { dateAdapter: AdapterDateFns, children: [_jsx(Box, { htmlFor: 'date', component: 'label', hidden: true, children: "Date" }), _jsx(DatePicker, { renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { inputProps: __assign(__assign({}, params.inputProps), { readOnly: true }), variant: 'standard', sx: {
                                                        svg: {
                                                            '& .cls-1': {
                                                                fill: theme.palette.customColor.primaryGreen,
                                                            },
                                                        },
                                                    } }))); }, components: {
                                                    OpenPickerIcon: OpenPickerIcon,
                                                }, label: '', disableFuture: true, value: selectedDay, onChange: function (day) {
                                                    var date = moment(day).format(DATE_FORMAT);
                                                    setCurrentDay(date);
                                                    dispatch(setSelectedDate(day, GraphView.DAY));
                                                }, InputProps: {
                                                    disableUnderline: true,
                                                    style: {
                                                        backgroundColor: 'white',
                                                        paddingLeft: 8,
                                                        paddingRight: 8,
                                                        borderRadius: 5,
                                                        width: 160,
                                                        border: "1px solid ".concat(theme.palette.customColor.lighterBlack),
                                                    },
                                                } })] }), _jsx(NavigateNextIcon, { style: {
                                            cursor: 'pointer',
                                            color: theme.palette.customColor.primaryGreen,
                                        }, onClick: navigateNextDay })] })), currentState === GraphView.WEEK && (_jsx(WeekPicker, { wellnessParam: wellnessParam, setCurrentWeek: setCurrentWeek })), currentState === GraphView.MONTH && (_jsx(MonthPicker, { wellnessParam: wellnessParam, setCurrentMonth: setCurrentMonth }))] })] }), _jsx(Box, { mt: 2, children: children })] }));
};
export { WellnessWrapper };
