import { makeStyles } from 'tss-react/mui';
export var filtersStyle = makeStyles()(function (theme) { return ({
    container: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '16px',
        boxShadow: "0 2px 8px 0 ".concat(theme.palette.customColor.boxShadow),
        alignItems: 'center',
        padding: '16px',
        display: 'flex',
        gap: '1rem',
        flexWrap: 'wrap',
    },
    zoneContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1,
        '& > span': {
            fontWeight: 600,
            justifySelf: 'flex-start',
        },
    },
    gridContainer: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        gap: '1em',
        alignItems: 'center',
    },
    blankCheckbox: {
        width: '16px',
        height: '16px',
        border: "1px solid ".concat(theme.palette.customColor.checkboxBlack),
        boxSizing: 'content-box',
        margin: '0 2px 0 4px',
    },
    selectRoot: {
        height: '40px',
        color: theme.palette.customColor.black,
    },
}); });
