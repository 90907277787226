var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-len */
import moment from 'moment';
import get from 'lodash.get';
import { isEmpty } from 'lodash';
import { Box, Button, Grid, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { VictoryArea, VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryStack, VictoryTooltip, VictoryVoronoiContainer, VictoryScatter, VictoryGroup, VictoryLine, } from 'victory';
import { getTimestamp, kgToLbs, roundOff, convertKgToLbs, numberRoundOff, createDashedLine, getMinMaxAxis, getCurrentSenior, convertLbsToKg, } from 'globals/global.functions';
import { DATE_FORMAT_SHORT, DIALOG_TYPES } from 'globals/global.constants';
import { ReactComponent as DiffArrow } from 'assets/icons/DiffArrow.svg';
import { bodyHealthStyle } from '../BodyHealth.styles';
import { wellnessDashboardStyle } from '../../../WellnessDashboard.style';
import clsx from 'clsx';
import React from 'react';
import { useMutation, useQuery } from 'utilities/react-query';
import { BODY_HEALTH_GOAL_TYPES, GraphView } from 'globals/enums';
import { getBodyHealthGoalService, postBodyHealthGoalService, } from 'services/bodyHealthGoalService/bodyHealthGoal.service';
import { useAppDispatch } from 'hooks/reduxHooks';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { getSeniorFullName, openOverlayDialog, showError, } from 'store/commonReducer/common.action';
import { InputText } from 'common/InputFields/component/Input.component';
import globalUseStyles from 'config/global.styles';
import SemiCircularProgress from 'common/CircularProgress/SemiCircularProgress.component';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
var viewTypeLabel = {
    day: 'Day',
    week: 'Week',
    month: 'Month',
};
var BodyWeight = function (_a) {
    var _b, _c, _d;
    var _e, _f, _g, _h;
    var type = _a.type, summary = _a.summary, difference = _a.difference, graph = _a.graph, lastRecordedDate = _a.lastRecordedDate, graphTickValues = _a.graphTickValues, reRender = _a.reRender;
    var theme = useTheme();
    var globalClasses = wellnessDashboardStyle().classes;
    var appClasses = globalUseStyles().classes;
    var classes = bodyHealthStyle().classes;
    var weightClasses = weightStyle().classes;
    var dispatch = useAppDispatch();
    var fullName = dispatch(getSeniorFullName());
    var _j = React.useState(false), isEdit = _j[0], setIsEdit = _j[1];
    var _k = React.useState(0), targetWeight = _k[0], setTargetWeight = _k[1];
    var _l = useQuery({
        queryKey: ['targetWeight'],
        queryFn: function () { return __awaiter(void 0, void 0, Promise, function () {
            var currentSenior, params, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentSenior = getCurrentSenior();
                        params = {
                            senior_id: currentSenior.senior_id,
                            account_id: currentSenior.account_id,
                            target_type: BODY_HEALTH_GOAL_TYPES.WEIGHT,
                        };
                        return [4 /*yield*/, getBodyHealthGoalService(params)];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); },
        onSuccess: function (data) {
            setTargetWeight(numberRoundOff(convertKgToLbs(data), 0));
        },
        cacheTime: 0,
    }), isLoading = _l.isLoading, refetchTarget = _l.refetch;
    var postTargetWeightMutation = useMutation({
        mutationFn: function (goal) {
            dispatch(showApplicationLoader());
            var currentSenior = getCurrentSenior();
            var params = {
                senior_id: currentSenior.senior_id,
                account_id: currentSenior.account_id,
                target_type: BODY_HEALTH_GOAL_TYPES.WEIGHT,
                target_score: goal,
            };
            return postBodyHealthGoalService(params);
        },
        onSuccess: function () {
            dispatch(hideApplicationLoader());
            dispatch(openOverlayDialog({
                type: DIALOG_TYPES.SUCCESS,
                firstMessage: "Weight Goal is updated successfully for ".concat(fullName, "."),
            }));
            reRender();
            refetchTarget();
        },
        onError: function (error) {
            setIsEdit(false);
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    });
    var handleSetWeightGoal = React.useCallback(function () {
        postTargetWeightMutation.mutate(numberRoundOff(convertLbsToKg(targetWeight, false), 2));
    }, [targetWeight, postTargetWeightMutation]);
    var avgBMI = get(summary, 'extremities.raw_data.average_bmi', 0);
    var previousWeight = get(difference, 'previous_mass.weight', 0);
    var currentWeight = get(difference, 'current_mass.weight', 0);
    var previousWeightLbs = numberRoundOff(convertKgToLbs(previousWeight));
    var currentWeightLbs = numberRoundOff(convertKgToLbs(currentWeight));
    var diffWeight = 0;
    if (previousWeight && currentWeight)
        diffWeight = numberRoundOff(currentWeightLbs - previousWeightLbs);
    var gain = diffWeight > 0 ? diffWeight : '-';
    var loss = diffWeight < 0 ? -diffWeight : '-';
    var totalWeight = [];
    var createWeightGraph = function (arr) {
        var chart = [];
        var chart2 = [];
        if (isEmpty(arr))
            return;
        arr.map(function (data) {
            var weight = kgToLbs(data.weight);
            var day = moment(getTimestamp(data.time)).format(DATE_FORMAT_SHORT);
            totalWeight.push(weight);
            chart.push({
                x: day,
                y: weight,
            });
        });
        graphTickValues.forEach(function (tick) {
            var temp = chart.find(function (x) { return x.x === tick; });
            if (temp !== undefined) {
                chart2.push(temp);
            }
            else {
                chart2.push({
                    x: tick,
                    y: null,
                });
            }
        });
        createDashedLine(chart2);
        return chart2;
    };
    var victoryAreaChartData = createWeightGraph(graph.weight);
    var interupptionLine = createDashedLine(victoryAreaChartData);
    var dailyAvgChartConfig = {
        chart: { width: 1000 },
        label: { text: 'Daily Avg', dx: 50, dy: 10 },
        xAxis: { orientation: 'bottom' },
        yAxis: {
            orientation: 'right',
            style: {
                axis: { stroke: 'none' },
                tickLabels: {
                    fill: lastRecordedDate && theme.palette.customColor.lighterBlack,
                },
            },
        },
        area: {
            style: {
                data: {
                    fill: theme.palette.customColor.remGreen,
                    fillOpacity: 0.7,
                    stroke: theme.palette.customColor.moderateGreen,
                    strokeWidth: 3,
                },
                labels: { fontSize: 15, fill: theme.palette.customColor.labelRed },
            },
        },
    };
    var currentAvgWeight = numberRoundOff(convertKgToLbs((_e = summary === null || summary === void 0 ? void 0 : summary.goal_percentile) === null || _e === void 0 ? void 0 : _e.current_weight));
    // const startWeight = numberRoundOff(
    //   convertKgToLbs(summary?.goal_percentile?.start_body_weight),
    // );
    var startWeight = numberRoundOff((_f = summary === null || summary === void 0 ? void 0 : summary.goal_percentile) === null || _f === void 0 ? void 0 : _f.start_body_weight);
    var currentTargetWeight = numberRoundOff(convertKgToLbs((_g = summary === null || summary === void 0 ? void 0 : summary.goal_percentile) === null || _g === void 0 ? void 0 : _g.target_weight), 0);
    var weightPercent = numberRoundOff((_h = summary === null || summary === void 0 ? void 0 : summary.goal_percentile) === null || _h === void 0 ? void 0 : _h.body_weight_goal_percentage);
    return (_jsx(_Fragment, { children: _jsxs(Grid, { container: true, direction: 'row', justifyContent: 'center', alignItems: 'center', "data-testid": 'body-weight-graph', children: [_jsxs(Grid, { item: true, xs: 12, sm: 12, md: 4, className: weightClasses.bodyContainerLeft, children: [_jsxs(Box, { className: classes.headerContainer, children: [_jsx(Box, { className: globalClasses.cardTitle, children: "Weight" }), !lastRecordedDate && (_jsxs(Box, { className: globalClasses.cardSubTitle, children: [_jsxs(Box, { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', children: [diffWeight !== 0 &&
                                                    (diffWeight > 0 ? (_jsx(Box, { style: {
                                                            marginRight: 5,
                                                            transform: 'rotate(180deg)',
                                                        }, children: _jsx(DiffArrow, { height: 25, width: 25, fill: theme.palette.customColor.hydrationGreen }) })) : (_jsx(Box, { style: {
                                                            marginRight: 5,
                                                            transform: 'rotate(0deg)',
                                                        }, children: _jsx(DiffArrow, { height: 25, width: 25, fill: theme.palette.customColor.hydrationRed }) }))), roundOff(diffWeight)] }), _jsx(Box, { fontSize: 18, className: weightClasses.noDataLabelColor, children: "".concat(viewTypeLabel[type], " Change") })] }))] }), _jsxs(Box, { display: 'flex', alignItems: 'center', flexDirection: 'column', children: [_jsx(SemiCircularProgress, { value: weightPercent, display: _jsxs(_Fragment, { children: ["Current:", ' ', _jsx(ShowHyphen, { children: !!currentAvgWeight && "".concat(currentAvgWeight, "lbs") })] }), color: theme.palette.customColor.primaryGreen, bgColor: theme.palette.customColor.bgGrey, size: 300 }), _jsxs(Box, { width: '100%', maxWidth: '400px', display: 'flex', justifyContent: 'space-between', children: [_jsxs(Box, { children: ["Start:", ' ', _jsx(ShowHyphen, { children: !!startWeight && "".concat(startWeight, "lbs") })] }), _jsxs(Box, { children: ["Target:", ' ', _jsx(ShowHyphen, { children: !!currentTargetWeight && "".concat(currentTargetWeight, "lbs") })] })] })] }), _jsxs(Grid, { container: true, direction: 'row', justifyContent: 'center', alignItems: 'stretch', mt: 3, mb: 3, gap: '8px', children: [_jsx(Grid, { item: true, width: '240px', children: _jsxs(Box, { className: classes.summaryBox, children: [_jsx(Box, { className: classes.summaryBoxLabel, alignSelf: 'flex-start', children: "TARGET WEIGHT" }), _jsxs(Box, { display: 'flex', gap: '10px', marginBottom: '5px', width: '100%', children: [_jsx(InputText, { type: 'number', value: targetWeight, onChange: function (e) {
                                                            setTargetWeight(parseInt(e.target.value));
                                                        }, onBlur: function () {
                                                            if (!targetWeight || targetWeight < 1) {
                                                                setTargetWeight(1);
                                                            }
                                                            else if (targetWeight > 999) {
                                                                setTargetWeight(999);
                                                            }
                                                        }, style: { borderRadius: '10px', width: '97px' }, disabled: !isEdit }), _jsx(Button, { variant: 'contained', color: 'primary', className: appClasses.smallButton, style: { minWidth: '120px' }, onClick: isEdit ? handleSetWeightGoal : function () { return setIsEdit(true); }, "data-testid": 'weight-goal-button', disabled: isLoading ||
                                                            (isEdit && (!targetWeight || targetWeight < 1)), children: isEdit ? 'Save' : 'Edit' })] })] }) }), _jsx(Grid, { item: true, flexGrow: 1, maxWidth: '128px', children: _jsxs(Box, { className: classes.summaryBox, children: [_jsx(Box, { className: classes.summaryBoxLabel, children: "GAIN" }), _jsx(Box, { className: clsx((_b = {},
                                                    _b[classes.summaryBoxValue] = !lastRecordedDate,
                                                    _b[classes.lastRecordedColor] = lastRecordedDate,
                                                    _b)), children: gain })] }) }), _jsx(Grid, { item: true, flexGrow: 1, maxWidth: '128px', children: _jsxs(Box, { className: classes.summaryBox, children: [_jsx(Box, { className: classes.summaryBoxLabel, children: "LOSS" }), _jsx(Box, { className: clsx((_c = {},
                                                    _c[classes.summaryBoxValue] = !lastRecordedDate,
                                                    _c[classes.lastRecordedColor] = lastRecordedDate,
                                                    _c)), children: loss })] }) })] }), _jsxs(Box, { children: [_jsxs(Box, { mt: 2, display: 'flex', justifyContent: 'space-between', children: [_jsx(Box, { fontSize: 18, color: theme.palette.customColor.lighterBlack, children: "BMI" }), _jsx(Box, { className: clsx((_d = {},
                                                _d[weightClasses.bMIValueColor] = !lastRecordedDate,
                                                _d[weightClasses.noDataValueColor] = lastRecordedDate,
                                                _d)), fontSize: 18, children: roundOff(avgBMI) })] }), type === GraphView.DAY && (_jsxs(_Fragment, { children: [_jsxs(VictoryChart, { height: 50, padding: { left: 0, right: 0 }, children: [_jsxs(VictoryStack, { colorScale: [
                                                        theme.palette.customColor.moderateBlue,
                                                        theme.palette.customColor.stackGreen,
                                                        theme.palette.customColor.stackYellow,
                                                        theme.palette.customColor.hydrationRed,
                                                    ], horizontal: true, children: [_jsx(VictoryBar, { data: [{ x: 'bmi', y: 1, label: 'Underweight' }], cornerRadius: { bottomRight: 16, bottomLeft: 16 }, barWidth: 32, labelComponent: _jsx(VictoryLabel, { dx: function (_a) {
                                                                    var datum = _a.datum;
                                                                    return -datum.y - 4;
                                                                }, textAnchor: 'end', verticalAnchor: 'middle' }) }), _jsx(VictoryBar, { data: [{ x: 'bmi', y: 1, label: 'Normal' }], barWidth: 32, labelComponent: _jsx(VictoryLabel, { dx: function (_a) {
                                                                    var datum = _a.datum;
                                                                    return -datum.y - 20;
                                                                }, textAnchor: 'end', verticalAnchor: 'middle' }) }), _jsx(VictoryBar, { data: [{ x: 'bmi', y: 1, label: 'Overweight' }], barWidth: 32, labelComponent: _jsx(VictoryLabel, { dx: function (_a) {
                                                                    var datum = _a.datum;
                                                                    return -datum.y - 8;
                                                                }, textAnchor: 'end', verticalAnchor: 'middle' }) }), _jsx(VictoryBar, { data: [{ x: 'bmi', y: 1, label: 'Obese' }], barWidth: 32, cornerRadius: { topRight: 16, topLeft: 16 }, labelComponent: _jsx(VictoryLabel, { dx: function (_a) {
                                                                    var datum = _a.datum;
                                                                    return -datum.y - 25;
                                                                }, textAnchor: 'end', verticalAnchor: 'middle' }) })] }), _jsx(VictoryAxis, { style: { axis: { stroke: 'transparent' } }, tickFormat: function () { return ''; } })] }), _jsxs(Box, { display: 'flex', position: 'relative', width: '100%', bottom: '8px', color: !lastRecordedDate
                                                ? theme.palette.customColor.primaryGreen
                                                : theme.palette.customColor.noDataGrey, children: [_jsx(Box, { position: 'relative', left: '25%', children: "18" }), _jsx(Box, { position: 'relative', left: '44%', children: "25" }), _jsx(Box, { position: 'relative', left: '63%', children: "30" })] })] }))] })] }), _jsxs(Grid, { item: true, xs: 12, sm: 12, md: 8, className: weightClasses.bodyContainerRight, children: [_jsxs(Box, { display: 'flex', justifyContent: 'space-between', children: [_jsx(Box, { className: globalClasses.cardTitle, ml: 2, mb: 4, children: "Daily Avg" }), lastRecordedDate && (_jsxs(Box, { className: globalClasses.lastRecordedTitle, children: ["Last recorded \u00A0", lastRecordedDate] }))] }), _jsx(Box, { className: weightClasses.activityContainer, children: isEmpty(victoryAreaChartData) ? (_jsxs(Box, { display: 'flex', flexDirection: 'column', mt: 12, width: '100%', children: [_jsx(Box, { style: {
                                            textAlign: 'center',
                                            width: '100%',
                                            color: theme.palette.customColor.lighterBlack,
                                            fontSize: 18,
                                        }, children: "No Data" }), _jsx(VictoryChart, { width: dailyAvgChartConfig.chart.width, height: 150, children: _jsx(VictoryAxis, { orientation: dailyAvgChartConfig.xAxis.orientation, fixLabelOverlap: true, tickValues: graphTickValues, style: {
                                                axis: { stroke: theme.palette.customColor.borderGrey },
                                                tickLabels: {
                                                    fill: theme.palette.customColor.lighterBlack,
                                                },
                                            } }) })] })) : (_jsxs(VictoryChart, { width: dailyAvgChartConfig.chart.width, minDomain: {
                                    y: getMinMaxAxis(totalWeight, 'min'),
                                }, maxDomain: {
                                    y: getMinMaxAxis(totalWeight, 'max'),
                                }, containerComponent: _jsx(VictoryVoronoiContainer, { voronoiBlacklist: ['interuptionLine'], labels: function (_a) {
                                        var datum = _a.datum;
                                        if ("".concat(roundOff(datum.y)) === '-') {
                                            return '';
                                        }
                                        return "".concat(roundOff(datum.y));
                                    }, labelComponent: _jsx(VictoryTooltip, { cornerRadius: 0, flyoutStyle: {
                                            stroke: 'transparent',
                                            strokeWidth: 0,
                                            fontWeight: 300,
                                            fontSize: 12,
                                            fill: theme.palette.background.green,
                                        }, dy: -7, constrainToVisibleArea: true, style: {
                                            fontSize: 20,
                                            color: theme.palette.customColor.titleBlack,
                                        } }) }), children: [_jsx("defs", { children: _jsxs("linearGradient", { id: 'grad', x1: '1', y1: '1', x2: '1', y2: '0', children: [_jsx("stop", { offset: '0', stopColor: 'white', stopOpacity: '1' }), _jsx("stop", { offset: '1', stopColor: theme.palette.customColor.intenseGreen, stopOpacity: '1' })] }) }), _jsx(VictoryAxis, { orientation: dailyAvgChartConfig.xAxis.orientation, fixLabelOverlap: true, tickValues: graphTickValues, style: {
                                            axis: {
                                                stroke: lastRecordedDate
                                                    ? theme.palette.customColor.borderGrey
                                                    : theme.palette.customColor.axisBlack,
                                            },
                                            tickLabels: {
                                                fill: lastRecordedDate &&
                                                    theme.palette.customColor.lighterBlack,
                                            },
                                        } }), _jsx(VictoryAxis, { orientation: dailyAvgChartConfig.yAxis.orientation, dependentAxis: true, style: dailyAvgChartConfig.yAxis.style, label: 'lbs', axisLabelComponent: _jsx(VictoryLabel, { angle: 0, dy: -130, style: {
                                                fontWeight: 600,
                                                fontSize: 15,
                                                fill: lastRecordedDate &&
                                                    theme.palette.customColor.lighterBlack,
                                            } }) }), _jsxs(VictoryGroup, { style: {
                                            data: {
                                                fillOpacity: 0.7,
                                                stroke: theme.palette.customColor.intenseGreen,
                                                strokeWidth: 2,
                                            },
                                        }, data: victoryAreaChartData, color: theme.palette.common.white, children: [_jsx(VictoryArea, { style: {
                                                    data: { fill: 'url(#grad)' },
                                                }, name: 'area' }), _jsx(VictoryScatter, { style: { data: { fill: theme.palette.common.white } }, size: 4 })] }), _jsx(VictoryLine, { style: {
                                            data: {
                                                stroke: theme.palette.customColor.primaryGreen,
                                                strokeWidth: 2,
                                                strokeDasharray: '4,4',
                                            },
                                        }, name: 'interuptionLine', data: interupptionLine })] })) })] })] }) }));
};
export { BodyWeight };
var weightStyle = makeStyles()(function (theme) {
    var _a;
    return ({
        bodyContainerLeft: (_a = {
                borderRight: "1px solid ".concat(theme.palette.customColor.lightSeparator),
                paddingRight: 16
            },
            _a[theme.breakpoints.down('md')] = {
                borderRight: "0",
                paddingRight: 0,
            },
            _a),
        bodyContainerRight: { alignSelf: 'flex-start' },
        circle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 180,
            width: 180,
            borderRadius: '50%',
            padding: 5,
            background: theme.palette.background.green,
        },
        circleValue: {
            fontSize: 44,
            fontWeight: 'bold',
        },
        activityContainer: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
        },
        noDataValueColor: {
            color: theme.palette.customColor.lightBlack,
        },
        bMIValueColor: {
            color: theme.palette.customColor.primaryGreen,
        },
        noDataLabelColor: {
            color: theme.palette.customColor.lighterBlack,
        },
    });
});
