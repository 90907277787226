import { makeStyles } from 'tss-react/mui';
export var facilityTypeSelectStyle = makeStyles()(function (theme, _a) {
    var _b, _c, _d, _e;
    var zoneColor = _a.zoneColor, hasAllOption = _a.hasAllOption;
    return ({
        root: {
            border: "1px solid ".concat(theme.palette.customColor.borderGrey),
            overflow: 'hidden',
            borderRadius: 8,
            color: ((_b = theme.palette[zoneColor]) === null || _b === void 0 ? void 0 : _b.contrastText) ||
                (hasAllOption
                    ? (_c = theme.palette.customColor) === null || _c === void 0 ? void 0 : _c.black
                    : (_d = theme.palette.customColor) === null || _d === void 0 ? void 0 : _d.disabledRemove),
            backgroundColor: ((_e = theme.palette[zoneColor]) === null || _e === void 0 ? void 0 : _e.main) || theme.palette.background.white,
            minWidth: '100%',
            height: '35px',
            '&.Mui-focused.MuiOutlinedInput-root fieldset': {
                borderWidth: 0,
            },
            '& .MuiSelect-icon': {
                color: theme.palette.primary.main,
                marginRight: 2,
            },
        },
    });
});
