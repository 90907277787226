import { makeStyles } from 'tss-react/mui';
export var zoneChartStyle = makeStyles()(function (theme) { return ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.16)',
        height: '100%',
        minHeight: 'fit-content',
        width: '100%',
        justifyContent: 'space-between',
    },
    header: {
        backgroundColor: theme.palette.customColor.primaryGreen,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 20px',
    },
    title: {
        color: theme.palette.common.white,
        fontSize: '18px',
        fontWeight: 600,
    },
    currentContainer: {
        backgroundColor: theme.palette.background.green,
        padding: '8px 20px',
    },
    currentLabel: {
        fontSize: '16px',
        fontWeight: 600,
    },
    currentValue: {
        fontSize: '20px',
        fontWeight: 600,
        margin: '0 4px',
    },
    loader: {
        padding: '8px 20px',
        display: 'flex',
        height: '350px',
        alignItems: 'center',
        justifyContent: 'center',
    },
}); });
