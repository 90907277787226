import { makeStyles } from 'tss-react/mui';
export var headerStyle = makeStyles()(function (theme) {
    var _a;
    return ({
        container: (_a = {
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.background.dark,
                height: '70px',
                padding: "0 5%",
                zIndex: 1000
            },
            _a[theme.breakpoints.down('xl')] = {
            // padding: `0 2%`,
            },
            _a),
        logoutButton: {
            borderRadius: 20,
        },
    });
});
